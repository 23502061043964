// Generated by Framer (3f9fff0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/TbmJG1iM0";

const cycleOrder = ["t28Zcl0Bb"];

const serializationHash = "framer-UzLfl"

const variantClassNames = {t28Zcl0Bb: "framer-v-1a7nhl5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, text, width, ...props}) => { return {...props, ebqIA4SR_: text ?? props.ebqIA4SR_ ?? "Comprehensive Dashboard", oW6eMm4uy: icon ?? props.oW6eMm4uy ?? {src: "https://framerusercontent.com/images/P0DjnLdQTyeI1p1BZjj5vpQJgY.svg"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ebqIA4SR_, oW6eMm4uy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "t28Zcl0Bb", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1a7nhl5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"t28Zcl0Bb"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-ji4fvi"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"ZKlZH3P9b"}><Image background={{alt: "", fit: "fill", pixelHeight: 10, pixelWidth: 14, sizes: "13px", ...toResponsiveImage(oW6eMm4uy)}} className={"framer-117f8mq"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"mHI3Fx6pe"}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-j0v3gb"} data-styles-preset={"TbmJG1iM0"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-ebfdfc6f-a0e8-414d-b30e-eeab99d6e438, rgb(158, 163, 191)))"}}>Comprehensive Dashboard</motion.p></React.Fragment>} className={"framer-yvtwj8"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"QD0_EsDhQ"} style={{"--extracted-r6o4lv": "var(--token-ebfdfc6f-a0e8-414d-b30e-eeab99d6e438, rgb(158, 163, 191))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={ebqIA4SR_} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UzLfl.framer-tpu0t3, .framer-UzLfl .framer-tpu0t3 { display: block; }", ".framer-UzLfl.framer-1a7nhl5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 973px; }", ".framer-UzLfl .framer-ji4fvi { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 2px 0px; position: relative; width: 13px; }", ".framer-UzLfl .framer-117f8mq { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 9px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 13px; }", ".framer-UzLfl .framer-yvtwj8 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UzLfl.framer-1a7nhl5, .framer-UzLfl .framer-ji4fvi, .framer-UzLfl .framer-117f8mq { gap: 0px; } .framer-UzLfl.framer-1a7nhl5 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-UzLfl.framer-1a7nhl5 > :first-child, .framer-UzLfl .framer-ji4fvi > :first-child, .framer-UzLfl .framer-117f8mq > :first-child { margin-left: 0px; } .framer-UzLfl.framer-1a7nhl5 > :last-child, .framer-UzLfl .framer-ji4fvi > :last-child, .framer-UzLfl .framer-117f8mq > :last-child { margin-right: 0px; } .framer-UzLfl .framer-ji4fvi > *, .framer-UzLfl .framer-117f8mq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 973
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"ebqIA4SR_":"text","oW6eMm4uy":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjeeBtic3x: React.ComponentType<Props> = withCSS(Component, css, "framer-UzLfl") as typeof Component;
export default FramerjeeBtic3x;

FramerjeeBtic3x.displayName = "Single List";

FramerjeeBtic3x.defaultProps = {height: 24, width: 973};

addPropertyControls(FramerjeeBtic3x, {ebqIA4SR_: {defaultValue: "Comprehensive Dashboard", displayTextArea: false, title: "Text", type: ControlType.String}, oW6eMm4uy: {__defaultAssetReference: "data:framer/asset-reference,P0DjnLdQTyeI1p1BZjj5vpQJgY.svg?originalFilename=check.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerjeeBtic3x, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})